<template>
  <section class="invoice-add-wrapper add-news-wrapper">

    <!-- Col: Left (Invoice Container) -->

    <b-row v-if="loading">
      <b-col
        cols="11"
        class="mx-auto"
      >
        <b-card class="mb-2">
          <b-card-text class="px-5 py-2">
            Loading...
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      v-else
      class="invoice-add"
    >
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card>
          <div style="position: absolute; top: 7px; right: 7px;">
            <span v-if="Array.isArray(getVariantTag(tag))">
              <b-badge
                v-for="(tag, index) in getVariantTag(tag)"
                :key="index"
                :variant="tag.color"
                :class="index === 0 ? 'mr-1' : ''"
              >
                {{ tag.text }}
              </b-badge>
            </span>
            <b-badge
              v-else
              :variant="getVariantTag(tag).color"
            >
              {{ getVariantTag(tag).text }}
            </b-badge>
            <div
              :class="`badge badge-light-${published ? 'success' : 'danger'} ml-1`"
              style="margin: 0 auto; width: fit-content;"
            >{{ published ? 'Published' : 'Not Published' }}</div>
          </div>
          <validation-observer ref="rules">
            <b-form @submit.prevent>
              <b-row class="mt-1">
                <div
                  v-if="!fetchedEmail && !published"
                  style="display: flex; margin: 20px auto 0 auto;"
                >
                  <b-spinner
                    type="border"
                    style="width: 1.5rem; height: 1.5rem;"
                    variant="info"
                    label="Loading..."
                  />
                </div>
                <b-col
                  v-if="!published && fetchedEmail"
                  cols="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="User"
                    rules="required"
                  >
                    <b-form-group
                      label="Users:"
                      label-for="email"
                    >
                      <multiselect
                        v-model="valueDestination"
                        tag-placeholder="Add this as new tag"
                        placeholder="Search or add a tag"
                        label="name"
                        track-by="code"
                        :options="destination"
                        :multiple="true"
                        :taggable="true"
                        @tag="addDestinations"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                  <validation-provider
                    #default="{ errors }"
                    name="Exclude Users"
                  >
                    <b-form-group
                      label="Exclude Users (optional):"
                      label-for="email"
                    >
                      <multiselect
                        v-model="valueExcludeUsers"
                        tag-placeholder="Add this as new tag"
                        placeholder="Search or add a tag"
                        label="name"
                        track-by="code"
                        :options="excludeUsers"
                        :multiple="true"
                        :taggable="true"
                        @tag="addDestinations"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  <validation-provider
                    #default="{ errors }"
                    name="title"
                    rules="required"
                  >
                    <b-form-group
                      label="Title"
                      label-for="client"
                    >
                      <b-form-input
                        id="client"
                        v-model="title"
                        placeholder="Title"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="body"
                    rules="required"
                  >
                    <label for="textarea-default">Body</label>
                    <quill-editor
                      v-if="user.userRole === 'Admin'"
                      v-model="content"
                      :options="snowOption"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Please enter a news content."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            :disabled="sending"
            @click="submitHandler"
          >
            <span v-if="sending">
              <b-spinner
                small
                type="grow"
              />
              Saving...
            </span>
            <span v-else>Save</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
            @click="cancelEditNews()"
          >
            Cancel
          </b-button>
          <date-picker
            v-if="!published"
            v-model="scheduleDate"
            format="DD-MM-YYYY | HH:mm"
            type="datetime"
            value-type="format"
            class="w-100"
            placeholder="Publish Date (optional)"
          />
          <div class="d-flex mt-1 justify-content-around">
            <span>Server Time:</span>
            <span>{{ serverDate }}</span>
          </div>
        </b-card>

        <b-card
          v-if="tag == 'Newsletter'"
          style="margin-top: -10px;"
        >
          <div
            class="d-flex badge badge-light-primary justify-content-center"
            style="margin: 0 auto; font-size: 15px; padding: 8px 0;"
          >Expire Notification Date</div>
          <date-picker
            v-model="expireNotificationDate"
            format="DD-MM-YYYY"
            type="date"
            value-type="format"
            class="w-100"
            style="margin-top: 5px;"
            placeholder="Expiration Date (optional)"
          />
        </b-card>
      </b-col>
    </b-row>

  </section>
</template>

<script>
/* eslint-disable */
import axios from '@/libs/axios'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'
import { quillEditor } from 'vue-quill-editor'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'

import {
  BRow, BCol, BCard, BCardText, BButton, BFormGroup, BFormInput, BForm, BSpinner,
  BBadge,
} from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardText,
    BButton,
    DatePicker,
    BFormGroup,
    BForm,
    BFormInput,
    quillEditor,
    Multiselect,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      sending: false,
      loading: true,
      id: 0,
      tag: '',
      title: '',
      content: '',
      published: false,
      scheduleDate: null,
      expireNotificationDate: null,
      serverDate: null,
      destination: [
        { name: 'All', code: 'All' },
        { name: 'Subscribed (and above)', code: 'Subscribed' },
        { name: 'Trial (and above)', code: 'Trial' },
        { name: 'Pending (and above)', code: 'Pending' },
        { name: 'Registered (and above)', code: 'Registered' },
      ],
      valueDestination: [],
      allUsers: [],
      fetchedEmail: false,
      excludeUsers: [
        { name: 'Subscribed (status)', code: 'Subscribed' },
        { name: 'Trial (status)', code: 'Trial' },
        { name: 'Pending (status)', code: 'Pending' },
        { name: 'Registered (status)', code: 'Registered' },
      ],
      valueExcludeUsers: [],
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
  },
  created() {
    if (this.user.isAdmin) {
      this.$store.dispatch('users/fetchUsersTag').then(tags => {
        tags.forEach(tag => {
          this.destination.push({ name: `${tag.tag_name} (tag)`, code: tag.tag_name })
          this.excludeUsers.push({ name: `${tag.tag_name} (tag)`, code: tag.tag_name })
        })
        this.$store.dispatch('users/fetchAllUsers').then(() => {
          this.allUsers = this.$store.state.users.allUsers
          this.allUsers.forEach(user => {
            this.destination.push({ name: user.email, code: user.uid })
          })
        })
      })
    }
    this.$store.dispatch('users/fetchAllUsers').then(res => {
      this.allUsers = res
    })
  },
  mounted() {
    this.viewNews()
    setInterval(() => {
      this.serverDate = new Date().toLocaleString('en-GB', {
        day: '2-digit', month: 'short', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'Iceland',
      })
    }, 1000)
  },
  methods: {
    addDestinations(value) {
      const tag = {
        name: value,
        code: value.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      }
      this.destination.push(tag)
      this.valueDestination.push(tag)
    },

    getVariantTag(tags) {
      if (tags === 'Product Updates') {
        return {
          color: 'light-warning',
          text: 'Product Updates',
        }
      }
      if (tags === 'Newsletter') {
        return {
          color: 'light-success',
          text: 'Newsletter',
        }
      }
      if (tags == 'Newsletter,Product Updates' || tags == 'Product Updates,Newsletter') {
        return tags.split(',').map(tag => {
          if (tag === 'Product Updates') {
            return {
              color: 'light-warning',
              text: 'Product Updates',
            }
          }
          if (tag === 'Newsletter') {
            return {
              color: 'light-success',
              text: 'Newsletter',
            }
          }
          return {}
        })
      }

      return {
        color: 'light-secondary',
        text: 'N/A',
      }
    },

    viewNews() {
      const { id } = this.$route.params

      this.loading = true

      axios.get(`/api/news/show/${id}`).then(res => {
        this.id = res.data.id
        this.tag = res.data.tag
        this.title = res.data.title
        this.content = res.data.content
        this.send_to = res.data.send_to.split('|')
        this.excluded_users = res.data.excluded_users && res.data.excluded_users.split('|')
        this.published = res.data.published
        this.scheduleDate = res.data.publish_date && moment(res.data.publish_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY | HH:mm')
        this.expireNotificationDate = res.data.expire_notification_date && moment(res.data.expire_notification_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
      }).catch(error => { console.log(error) }).finally(this.loading = false)

      if (!this.published) {
        const intervalFetchEmail = setInterval(() => {
          if (this.send_to?.length > 0 && this.allUsers?.length > 0) {
            this.valueDestination = []
            this.send_to.forEach(item => {
              if (this.allUsers.find(user => user.uid == item)) {
                this.valueDestination.push({ name: this.allUsers.find(user => user.uid == item).email, code: item })}
              else {
                this.valueDestination.push({ name: item, code: item })
              }
            })

            if (this.excluded_users?.length > 0) {
              this.valueExcludeUsers = []
              this.excluded_users.forEach(item => {
                this.valueExcludeUsers.push({ name: item, code: item })
              })
            }

            this.fetchedEmail = true;
            clearInterval(intervalFetchEmail);
          }
        }, 250)
      }
    },

    cancelEditNews() {
      this.$router.push('/news/list').catch(error => { console.log(error) })
    },

    submitHandler() {
      this.confirmationAlert(`Are you sure you want to update this "${this.tag}"?`).then(update_res => {
        if (update_res.isConfirmed) {
          this.$refs.rules.validate().then(success => {
            if (success) {
              const payload = {
                title: this.title,
                content: this.content,
                destination: this.valueDestination.map(item => item.code).join('|'),
                date: this.scheduleDate && moment(this.scheduleDate, "DD-MM-YYYY | HH:mm").format("YYYY-MM-DD HH:mm"),
                expire: this.expireNotificationDate && moment(this.expireNotificationDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                excluded_users: this.valueExcludeUsers?.length > 0 ? this.valueExcludeUsers.map(item => item.code).join('|') : null,
              }
              const dateUnix = moment(this.serverDate, "DD MMM, HH:mm").valueOf();
              const scheduleUnix = moment(this.scheduleDate, "DD-MM-YYYY | HH:mm").valueOf();
              const expireUnix = moment(this.expireNotificationDate, "DD-MM-YYYY").valueOf();

              if (!this.scheduleDate && !this.published) {
                this.notify({
                  text: 'Please enter a date for Schedule Date !',
                  variant: 'danger',
                })
                return
              }
              if (this.scheduleDate && scheduleUnix < dateUnix) {
                this.notify({
                  text: 'Please enter a date from the future for Schedule Date !',
                  variant: 'danger',
                })
                return
              }
              if (this.expireNotificationDate && this.tag === 'Newsletter' && expireUnix < dateUnix) {
                this.notify({
                  text: 'Please enter a date from the future for Expiration Date!',
                  variant: 'danger',
                })
                return
              }
              if (expireUnix <= scheduleUnix) {
                this.notify({
                  text: 'Please enter a date before Schedule Date for Expiration Date !',
                  variant: 'danger',
                })
                return
              }

              const headers = { 'Content-Type': 'application/json' }
              this.sending = true
              axios.put(`/api/news/update/${this.id}`, payload, { headers }).then(result => {
                if (result.data.action === 'OK') this.$router.push(`/news/detail/${this.id}`).catch(error => { console.log(error) })
                this.sending = false
              }).catch(error => { console.log(error) })
            }
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.add-news-wrapper .ql-editor {
  min-height: 320px;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
